import React from 'react'
import { Link } from 'react-router-dom'
import StatusTag from './StatusTag'

const BlogNav = ({ to, status, text }) => (
    <nav className="level">
      <div className="level-left">
        <Link className="level-item button is-small is-link is-outlined" to={to}>{text}</Link>
      </div>
      <div className="level-right">
        <StatusTag status={status} />
      </div>
    </nav>
  )

  export default BlogNav