import React from 'react';

const Contact = () => {
    return (
        <div className="row">
            <div className="col-12">
                <h1>Contact</h1>
                <p>You can contact me via twitter on <a href="https://twitter.com/Talkarbob" target="_blank">@talkarbob</a></p>
                <p>Or you can send a mail to <a href="mailto:privat@tommyarne.dk">privat@tommyarne.dk</a></p> 
            </div>
        </div>
    );
}

export default Contact;