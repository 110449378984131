import React from 'react';
import { HashRouter as Router, Route, Link } from 'react-router-dom'

import Index from './Index';
import About from './About';
import Contact from './Contact';
import BlogPost from './BlogPost';
import Blog from './Blog';

const Navigation = () => {

    return (
        <Router>
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark" style={{ paddingLeft: 0 }}>
                    <Link className="nav-brand nav-link" to="/" style={{ paddingLeft: 0 }}>TommyArne.dk</Link>
                    <ul className="navbar-nav mr-auto">
                        <li><Link className="nav-link" to="/blog">Blog</Link></li>
                        <li><Link className="nav-link" to="/about">About</Link></li>
                        <li><Link className="nav-link" to="/contact">Contact</Link></li>
                    </ul>
                </nav>
                <Route exact path="/" component={Index} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path='/blog' component={Blog} exact/>
                <Route path='/blog/:blogPost' component={BlogPost}/>
            </div>
        </Router>
    );
}
export default Navigation;