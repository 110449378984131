import React from 'react';
import Blog from './Blog';

const Index = () => {
    return (
    <>
        <h1>Welcome to my site</h1>
        <p>Here I'll put random experiments, thoughts etc.</p>
    </>
    );
}

export default Index;