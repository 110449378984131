import React, {useEffect, useState} from 'react';
import * as contentful from 'contentful'
import PageContent from './PageContent'
import BlogNav from './BlogNav'
import BlogContent from './BlogContent'
import { useLocation } from 'react-router-dom';

const BlogPost = () => {
    const [client, setClient] = useState(null);
    const [post, setPost] = useState();
    const location = useLocation();

    useEffect(()=>{
        setClient(contentful.createClient({
            space: 'm1k3p91bljw1',
            accessToken: '5PzDiC-pC3gaW2ABQ2OWcA_a5DEYxzcMuAlkam-bf1o'
        }));
    }, []);

    useEffect(()=>{
    if(!client)
        return;
    
    client.getEntries().then(entries => {
        entries.items.map(({fields},i)=>{
        if(fields.path===location.pathname.replace('/blog/','')){
setPost(fields);
        }
    });
    });
},[client]);

     if(!post)
        return null;
    return(
        <PageContent>
        <BlogNav date={post.date} to="/blog" text="Back to Blog" />
        <BlogContent {...post } />
      </PageContent>
    );
}

  export default BlogPost