import React from 'react';
import Navigation from './Components/Navigation';
import Container from 'react-bootstrap/Container';
import './App.css';
import 'bootswatch/dist/darkly/bootstrap.min.css';
import HttpsRedirect from 'react-https-redirect';

function App() {
  return (
    <HttpsRedirect>
      <Container>
        <Navigation />
      </Container>
    </HttpsRedirect>
  );
}

export default App;
