import React from 'react';
import moment from 'moment';

const About = () => {
    var today = moment();
    var birthday = moment('18/10/1991', 'DD/MM/YYYY');
    const age = today.diff(birthday, 'years');
    return (
        <div className="row">
            <div className="col-12">
                <h1>About</h1>
                <p>My name is Tommy, and i'm a {age} year old man, who works with various software development projects.
                I've worked with everything from ReactJs and AngularJs, to hardcore Oracle SQL
                At the moment, i work mostly with ASP.NET and ReactJs, with some MSSQL mixed in for good measure</p>
                <p>In my spare time, i tend to take on some projects as well, or might even do a project for myself!<br />
                    I do however, spend some time playing games too. Mostly strategy games like StarCraft and Civilization, but also time sinks, such as WoW.<br />
                </p>
                <h2>Education and work history</h2>
                <p>
                    I'm educated in Computer Science, class of 2013, at the school of Business Academy South West, which is located in Esbjerg, Denmark. <br />
                    There i was taught Java, at first, but moved on to learn C#, especially in the .NET framework. <br />
                    I took part in a self taught study group, where we learned about MVC in ASP.NET as well, since we didn't think the other courses were relevant enough for us, <br />
                    considering where we thought the world was heading, technology wise. <br />
                    Furthermore, i got certifed in CCNA 1 and 2 too. 
                </p>
                <p>
                    Right after finishing my education, i was hired as a developer, although with the obscurring title of "Technical Analyst" at Telenor. <br />
                    Here i worked in a group with two other developers and a student helper. <br />
                    My tasks here, consisted mostly of replacing manual, repetitive tasks, with automated processes. <br />
                    I worked a lot with Oracle SQL, ASP.NET, and some QlikView. 
                </p>
                <p>
                    In 2014, i moved on from Telenor, and was hired as a developer at Freeway.<br />
                    Here i worked on a very large danish website, with many users. <br />
                    The focus here was always on delivering a good experience for the web site users. <br />
                    So that meant the web pages had to be snappy, and the SQL queries had to perform. <br />
                    I worked mostly with MSSQL and ASP.NET.<br />
                    I was also charged with developing a Windows Phone 10 app, which also had to run on Windows 10. 
                </p>
                <p>
                    In 2016 i was back at Telenor for about 10 months again. <br />
                    The tasks were very identical to what they were, the last time i was there.
                </p>
                <p>
                    Which brings us to my current workplace. <br />
                    In 2017, i was hired as a Software Developer at InMobile. <br />
                    Here i have work consisting of both frontend and backend work. <br />
                    I work on everything from server management to setting up styling.
                </p>
                <p>
                    I've also taking on various side projects, where i've worked with Magento and Shopify webshops. 
                </p>
                <h2>Technologies</h2>
                <p>Below are listed some of the Technologies i've worked with.</p>
                <ul>
                    <li>MVC</li>
                    <li>.NET</li>
                    <li>C#</li>
                    <li>Javascript</li>
                    <li>Java</li>
                    <li>ReactJs</li>
                    <li>MSSQL</li>
                    <li>OracleSQL</li>
                    <li>Windows</li>
                    <li>Linux</li>

                </ul>
            </div>
        </div>
    );
}

export default About;