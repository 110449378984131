import React, {useState, useEffect} from 'react'
import * as Markdown from 'react-markdown'
import moment from 'moment'

const BlogContent = (props) => {
const [isSmallHeader, setIsSmallHeader] = useState(false);

useEffect(()=>{
  setIsSmallHeader(props.isSmallHeader);
}, [props.isSmallHeader])

return (
    <article className="media">
      <div className="media-content">
        <div className="content">
        <p><b>{moment(props.date).calendar(null, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'MMM Do YYYY'
          })}</b></p>
          {isSmallHeader && (<h4>{props.title}</h4>)}
          {!isSmallHeader && (<h1>{props.title}</h1>)}
          <Markdown
            source={
              props.limit
              ? props.content.split(" ").splice(0,props.limit).join(" ").concat('...')
              : props.content
            }
          />
        </div>
        { props.children }
      </div>
    </article>
  )
}
  export default BlogContent
