import React, {useEffect, useState} from 'react';
import * as contentful from 'contentful'
import BlogItem from './BlogItem'
import PageHeader from './PageHeader'
import '../Styles/blog.css'

const Blog = ()=>{
    const [client, setClient] = useState(null);
    const [posts, setPosts] = useState([]);

    useEffect(()=>{
        setClient(contentful.createClient({
            space: 'm1k3p91bljw1',
            accessToken: '5PzDiC-pC3gaW2ABQ2OWcA_a5DEYxzcMuAlkam-bf1o'
        }));
        
    }, []);

    useEffect(()=>{
    if(!client)
        return;
    
    client.getEntries().then(entries => {
        setPosts(entries.items);
      });
    },[client])

    if(!posts|| posts.length === 0 ||!client)
        return null;
    else
        return (
       
            <div className="row">
            <div className="col-12">
            <PageHeader color="is-info" title="Yet another blog">
                Just a place to put my thougts. Don't expect continuity
            </PageHeader>
            <br />
            {posts.map(({fields}, i)=>
                    <BlogItem key={i} {...fields} />
            )}
        </div>
    </div>
    );
};

export default Blog;