import React from 'react'
import BlogNav from './BlogNav'
import BlogContent from './BlogContent'

const BlogItem = (props) => (
    <div className="box">
      <BlogContent limit={20} isSmallHeader={true} {...props }>
        <div className="blog-content-blog-nav-container">
          <BlogNav date={props.date} to={{
            pathname: `/blog/${props.path}`,
            status: { props }
          }} 
          text="Read more..."
          />
        </div>
      </BlogContent>
    </div>
  )

  export default BlogItem